import $ from "jquery";

import { AuthService } from './auth';


function login() {
    localStorage.setItem('isRedirectedFromIdentity', 'true');
    window.location.href = 'https://eshop.kros.sk/login-redirect?redirectUrl=' + encodeURIComponent(window.location.href);
}

function logout() {
    window.location.href = 'https://eshop.kros.sk/logout-redirect?redirectUrl=' + encodeURIComponent(window.location.href);
}

function changePassword() {
    window.location.href = 'https://login.kros.sk/Manage/ChangePassword?returnUrl='
        + encodeURIComponent(window.location.href);
}

function goToKrosAccount() {
    window.location.href = 'https://eshop.kros.sk/account';
}

function getEmail() {

    new AuthService().getAccessToken().subscribe(accesToken =>
        $.ajax({
            url: "https://eshopapi.kros.sk/api/customer/email",
            headers: { "Authorization": `Bearer ${accesToken}` },
            success: function (result) {
                getAndSetAvatarsElements(result);
                var emailElements = document.getElementsByClassName("email-panacik");
                Array.prototype.forEach.call(emailElements, function (emailElement: Element) {
                    emailElement.innerHTML = result;
                });
            }
        })
    );

}

function setState(state: boolean) {
    var loginButtons = document.getElementsByClassName("login-button");
    var circleElements = document.getElementsByClassName("avatar-in-header");
    if (state) {
        Array.prototype.forEach.call(loginButtons, function (loginButton: Element) {
            $(loginButton).css('display', 'none');
        });
        Array.prototype.forEach.call(circleElements, function (circleElement: Element) {
            $(circleElement).css('display', 'flex');
        });
        getEmail();
        var logoutElements = document.getElementsByClassName("logout-panacik");
        Array.prototype.forEach.call(logoutElements, function (logoutElement: Element) {
            $(logoutElement).click(logout);
        });
        var changePasswordElements = document.getElementsByClassName("changePassword-panacik");
        Array.prototype.forEach.call(changePasswordElements, function (changePasswordElement: Element) {
            $(changePasswordElement).click(changePassword);
        });
        var krosAccountElements = document.getElementsByClassName("krosAccount-panacik");
        Array.prototype.forEach.call(krosAccountElements, function (krosAccountElement: Element) {
            $(krosAccountElement).click(goToKrosAccount);
        });
    } else {
        Array.prototype.forEach.call(circleElements, function (circleElement: Element) {
            $(circleElement).css('display', 'none');
        });
    }

    Array.prototype.forEach.call(loginButtons, function (loginButton: Element) {
        $(loginButton).click(login);
    });
}

function showContextMenu() {
    var els = document.getElementsByClassName('avatar-in-header');
    var offset = 0;
    if (window.innerWidth >= 770 && els.length >= 2) {
        // desktop
        offset = (els[0] as HTMLElement).offsetLeft;
    } else {
        // mobile
        offset = (els[1] as HTMLElement).offsetLeft;
    }
    Array.prototype.forEach.call(document.getElementsByClassName("context-menu"), function (contextMenuElement: Element) {
        $(contextMenuElement).css('display', 'flex');
        $(contextMenuElement).css('left', `calc(${offset}-280px)`);
    });
    Array.prototype.forEach.call(document.getElementsByClassName("black-bar"), function (contextMenuElement: Element) {
        $(contextMenuElement).css('display', 'flex');
    });
}

$(document).ready(() => {
    var authService = new AuthService();
    authService.isLoggedIn().subscribe(isLoggedIn => {
        setState(isLoggedIn);
        if (isLoggedIn && localStorage.getItem('isRedirectedFromIdentity') === 'true') {
            showContextMenu();
        }
    },
        () => setState(false));
});

$('html').click(function (event) {
    if ($(event.target).hasClass("avatar-in-header")) {
        showContextMenu();
    } else {
        Array.prototype.forEach.call(document.getElementsByClassName("context-menu"), function (contextMenuElement: Element) {
            $(contextMenuElement).css('display', 'none');
        });
        Array.prototype.forEach.call(document.getElementsByClassName("black-bar"), function (contextMenuElement: Element) {
            $(contextMenuElement).css('display', 'none');
        });
    }
    if (!$(event.target).hasClass("login-button")) {
        localStorage.setItem('isRedirectedFromIdentity', 'false');
    }
});

const bgColors = ['#fadee2', '#fce9e0', '#fdf3de', '#def3ed', '#d9f8ef', '#d9e2ea',
    '#d9effa', '#efe7f1', '#f6dbfd', '#d9d9d9', '#d9d9d9'];

const colors = ['#da1f3c', '#e86e30', '#f3ac1f', '#1fae84', '#00cd90', '#003974',
    '#0095da', '#9260a0', '#c00df2', '#4d4d4d', '#6f7585'];

function getHashCode(input: string) {
    let hash = 0;
    let i = 0;
    let upgradedInput = input.substring(0, input.lastIndexOf("@"));
    const len = upgradedInput.length;
    while (i < len) {
        hash = ((hash << 5) - hash + upgradedInput.charCodeAt(i++)) << 0;
    }
    return hash;
}

function getTwoCharsFromEmail(email: string): string {
    var result = email.trim()[0] + email.trim()[1];
    return result.toLocaleUpperCase();
}

function getAndSetAvatarsElements(email: string) {
    var bgColor = "transparent";
    var color = "#555555";
    var hash = getHashCode(email);
    bgColor = bgColors[Math.abs(hash) % bgColors.length];
    color = colors[Math.abs(hash) % colors.length];

    Array.prototype.forEach.call(document.getElementsByClassName("avatar-panacik"), function (avatarElement: HTMLElement) {
        setAvatarForElement(avatarElement, email, bgColor, color);
    });
    Array.prototype.forEach.call(document.getElementsByClassName("avatar-in-header"), function (avatarElement: HTMLElement) {
        setAvatarForElement(avatarElement, email, bgColor, color);
    });
}

function setAvatarForElement(element: HTMLElement, email: string, bgColor: string, color: string) {
    element.innerHTML = getTwoCharsFromEmail(email);
    element.style.backgroundColor = bgColor;
    element.style.color = color;
}