"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

const parseCookies = s => {
  if (s.length === 0) return {};
  const parsed = {};
  const pattern = new RegExp('\\s*;\\s*');
  s.split(pattern).forEach(i => {
    const [encodedKey, encodedValue] = i.split('=');
    const key = decodeURIComponent(encodedKey);
    const value = decodeURIComponent(encodedValue);
    parsed[key] = value;
  });
  return parsed;
};

exports.parseCookies = parseCookies;