"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

const format_cookie_1 = require("./format-cookie");

const parse_cookies_1 = require("./parse-cookies");

class CookieStorage {
  constructor(defaultOptions) {
    this._defaultOptions = Object.assign({
      domain: null,
      expires: null,
      path: null,
      secure: false
    }, defaultOptions);
    if (typeof Proxy !== 'undefined') return new Proxy(this, cookieStorageHandler);
  }

  get length() {
    const parsed = parse_cookies_1.parseCookies(this._getCookie());
    const keys = Object.keys(parsed);
    return keys.length;
  }

  clear() {
    const parsed = parse_cookies_1.parseCookies(this._getCookie());
    const keys = Object.keys(parsed);
    keys.forEach(key => this.removeItem(key));
  }

  getItem(key) {
    const parsed = parse_cookies_1.parseCookies(this._getCookie());
    return parsed.hasOwnProperty(key) ? parsed[key] : null;
  }

  key(index) {
    const parsed = parse_cookies_1.parseCookies(this._getCookie());
    const sortedKeys = Object.keys(parsed).sort();
    return index < sortedKeys.length ? sortedKeys[index] : null;
  }

  removeItem(key, cookieOptions) {
    const data = '';
    const options = Object.assign({}, this._defaultOptions, cookieOptions, {
      expires: new Date(0)
    });
    const formatted = format_cookie_1.formatCookie(key, data, options);

    this._setCookie(formatted);
  }

  setItem(key, data, options) {
    const opts = Object.assign({}, this._defaultOptions, options);
    const formatted = format_cookie_1.formatCookie(key, data, opts);

    this._setCookie(formatted);
  }

  _getCookie() {
    return typeof document === 'undefined' ? '' : typeof document.cookie === 'undefined' ? '' : document.cookie;
  }

  _setCookie(value) {
    document.cookie = value;
  }

}

exports.CookieStorage = CookieStorage;
const cookieStorageHandler = {
  defineProperty(target, p, attributes) {
    target.setItem(p.toString(), String(attributes.value));
    return true;
  },

  deleteProperty(target, p) {
    target.removeItem(p.toString());
    return true;
  },

  get(target, p, _receiver) {
    if (typeof p === 'string' && p in target) return target[p];
    const result = target.getItem(p.toString());
    return result !== null ? result : undefined;
  },

  getOwnPropertyDescriptor(target, p) {
    if (p in target) return undefined;
    return {
      configurable: true,
      enumerable: true,
      value: target.getItem(p.toString()),
      writable: true
    };
  },

  has(target, p) {
    if (typeof p === 'string' && p in target) return true;
    return target.getItem(p.toString()) !== null;
  },

  ownKeys(target) {
    const keys = [];

    for (let i = 0; i < target.length; i++) {
      const key = target.key(i);
      if (key !== null) keys.push(key);
    }

    return keys;
  },

  preventExtensions(_) {
    throw new TypeError('can\'t prevent extensions on this proxy object');
  },

  set(target, p, value, _) {
    target.setItem(p.toString(), String(value));
    return true;
  }

};