"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

const formatOptions = o => {
  const {
    path,
    domain,
    expires,
    secure
  } = o;
  const sameSiteValue = getSameSiteValue(o);
  return [typeof path === 'undefined' || path === null ? '' : ';path=' + path, typeof domain === 'undefined' || domain === null ? '' : ';domain=' + domain, typeof expires === 'undefined' || expires === null ? '' : ';expires=' + expires.toUTCString(), typeof secure === 'undefined' || secure === false ? '' : ';secure', sameSiteValue === null ? '' : ';SameSite=' + sameSiteValue].join('');
};

const getSameSiteValue = o => {
  const {
    sameSite
  } = o;
  if (typeof sameSite === 'undefined') return null;
  if (['lax', 'strict'].indexOf(sameSite.toLowerCase()) >= 0) return sameSite;
  return null;
};

const formatCookie = (k, d, o) => {
  return [encodeURIComponent(k), '=', encodeURIComponent(d), formatOptions(o)].join('');
};

exports.formatCookie = formatCookie;