import { CookieStorage } from 'cookie-storage';
import {
  WebStorageStateStore,
  UserManagerSettings,
  UserManager
} from 'oidc-client';
import { from } from 'rxjs';

export class  AuthService {

    constructor() {
    }

    private get userManager() {

        const cookieStorage = new CookieStorage({
          expires: new Date(Date.now() + 12096e5), // + two weeks
          domain: 'kros.sk'
        });
    
        const settings: UserManagerSettings = {
          authority: 'https://login.kros.sk',
          client_id: 'Kros.Website.Eshop',
          redirect_uri: 'https://eshop.kros.sk/login-callback',
          response_type: 'id_token token',
          scope: 'openid Kros.Website.Eshop',
          post_logout_redirect_uri: 'https://eshop.kros.sk/logout-callback',
          userStore: new WebStorageStateStore({ store: cookieStorage }),
          automaticSilentRenew: true,
          silent_redirect_uri: 'https://eshop.kros.sk/silent-callback'
        };
        return new UserManager(settings);
      }
    
      login() {
        this.userManager.signinRedirect();
      }
    
      verify() {
        return this.userManager.signinRedirectCallback();
      }
    
      isLoggedIn() {
        return from(this.userManager.getUser()
          .then(user => user && user.access_token && !user.expired));
      }
    
      getAccessToken() {
        return from(this.userManager.getUser()
            .then(user => user.access_token));
      }

      logout() {
        this.userManager.signoutRedirect();
      }
    
      getUser() {
        return from(this.userManager.getUser());
      }
}